import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contact_45successJtmrpHVWIjMeta } from "/vercel/path0/pages/contact-success.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_936E40VvcyMaMeta } from "/vercel/path0/pages/news/[item].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as _91item_93bm9CCcgfkyMeta } from "/vercel/path0/pages/rooms/[item].vue?macro=true";
import { default as indexshyPbhYt8IMeta } from "/vercel/path0/pages/rooms/index.vue?macro=true";
import { default as shopbVVgDM89cGMeta } from "/vercel/path0/pages/shop.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___en",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___no",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/no/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___nl",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/nl/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___de",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/de/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: contact_45successJtmrpHVWIjMeta?.name ?? "contact-success___en",
    path: contact_45successJtmrpHVWIjMeta?.path ?? "/contact-success",
    meta: contact_45successJtmrpHVWIjMeta || {},
    alias: contact_45successJtmrpHVWIjMeta?.alias || [],
    redirect: contact_45successJtmrpHVWIjMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: contact_45successJtmrpHVWIjMeta?.name ?? "contact-success___no",
    path: contact_45successJtmrpHVWIjMeta?.path ?? "/no/contact-success",
    meta: contact_45successJtmrpHVWIjMeta || {},
    alias: contact_45successJtmrpHVWIjMeta?.alias || [],
    redirect: contact_45successJtmrpHVWIjMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: contact_45successJtmrpHVWIjMeta?.name ?? "contact-success___nl",
    path: contact_45successJtmrpHVWIjMeta?.path ?? "/nl/contact-success",
    meta: contact_45successJtmrpHVWIjMeta || {},
    alias: contact_45successJtmrpHVWIjMeta?.alias || [],
    redirect: contact_45successJtmrpHVWIjMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: contact_45successJtmrpHVWIjMeta?.name ?? "contact-success___de",
    path: contact_45successJtmrpHVWIjMeta?.path ?? "/de/contact-success",
    meta: contact_45successJtmrpHVWIjMeta || {},
    alias: contact_45successJtmrpHVWIjMeta?.alias || [],
    redirect: contact_45successJtmrpHVWIjMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___en",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___no",
    path: contactJnsDpYVejzMeta?.path ?? "/no/kontakt",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___nl",
    path: contactJnsDpYVejzMeta?.path ?? "/nl/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___de",
    path: contactJnsDpYVejzMeta?.path ?? "/de/kontakt",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___en",
    path: faqM4LN7jxnMuMeta?.path ?? "/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___no",
    path: faqM4LN7jxnMuMeta?.path ?? "/no/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___nl",
    path: faqM4LN7jxnMuMeta?.path ?? "/nl/veelgestelde-vragen",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___de",
    path: faqM4LN7jxnMuMeta?.path ?? "/de/haufig-gestellte-fragen",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___en",
    path: galleryjDl5RbNP1cMeta?.path ?? "/gallery",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___no",
    path: galleryjDl5RbNP1cMeta?.path ?? "/no/galleri",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___nl",
    path: galleryjDl5RbNP1cMeta?.path ?? "/nl/galerij",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___de",
    path: galleryjDl5RbNP1cMeta?.path ?? "/de/galerie",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___en",
    path: highlightsxqIDtGMklEMeta?.path ?? "/location",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___no",
    path: highlightsxqIDtGMklEMeta?.path ?? "/no/plassering",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___nl",
    path: highlightsxqIDtGMklEMeta?.path ?? "/nl/locatie",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___de",
    path: highlightsxqIDtGMklEMeta?.path ?? "/de/location",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___no",
    path: indexe9Brt5DfdhMeta?.path ?? "/no",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___nl",
    path: indexe9Brt5DfdhMeta?.path ?? "/nl",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___de",
    path: indexe9Brt5DfdhMeta?.path ?? "/de",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91item_936E40VvcyMaMeta?.name ?? "news-item___en",
    path: _91item_936E40VvcyMaMeta?.path ?? "/news/:item()",
    meta: _91item_936E40VvcyMaMeta || {},
    alias: _91item_936E40VvcyMaMeta?.alias || [],
    redirect: _91item_936E40VvcyMaMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_936E40VvcyMaMeta?.name ?? "news-item___no",
    path: _91item_936E40VvcyMaMeta?.path ?? "/no/nyheter/:item()",
    meta: _91item_936E40VvcyMaMeta || {},
    alias: _91item_936E40VvcyMaMeta?.alias || [],
    redirect: _91item_936E40VvcyMaMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_936E40VvcyMaMeta?.name ?? "news-item___nl",
    path: _91item_936E40VvcyMaMeta?.path ?? "/nl/nieuws/:item()",
    meta: _91item_936E40VvcyMaMeta || {},
    alias: _91item_936E40VvcyMaMeta?.alias || [],
    redirect: _91item_936E40VvcyMaMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_936E40VvcyMaMeta?.name ?? "news-item___de",
    path: _91item_936E40VvcyMaMeta?.path ?? "/de/nachrichten/:item()",
    meta: _91item_936E40VvcyMaMeta || {},
    alias: _91item_936E40VvcyMaMeta?.alias || [],
    redirect: _91item_936E40VvcyMaMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: indexxeWP9UFz4RMeta?.name ?? "news___en",
    path: indexxeWP9UFz4RMeta?.path ?? "/news",
    meta: indexxeWP9UFz4RMeta || {},
    alias: indexxeWP9UFz4RMeta?.alias || [],
    redirect: indexxeWP9UFz4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexxeWP9UFz4RMeta?.name ?? "news___no",
    path: indexxeWP9UFz4RMeta?.path ?? "/no/nyheter",
    meta: indexxeWP9UFz4RMeta || {},
    alias: indexxeWP9UFz4RMeta?.alias || [],
    redirect: indexxeWP9UFz4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexxeWP9UFz4RMeta?.name ?? "news___nl",
    path: indexxeWP9UFz4RMeta?.path ?? "/nl/nieuws",
    meta: indexxeWP9UFz4RMeta || {},
    alias: indexxeWP9UFz4RMeta?.alias || [],
    redirect: indexxeWP9UFz4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexxeWP9UFz4RMeta?.name ?? "news___de",
    path: indexxeWP9UFz4RMeta?.path ?? "/de/nachrichten",
    meta: indexxeWP9UFz4RMeta || {},
    alias: indexxeWP9UFz4RMeta?.alias || [],
    redirect: indexxeWP9UFz4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___en",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/special-offers/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___no",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/no/arrangementer/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___nl",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/nl/arrangementen/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___de",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/de/arrangements/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___en",
    path: index1us1AaJFa5Meta?.path ?? "/special-offers",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___no",
    path: index1us1AaJFa5Meta?.path ?? "/no/arrangementer",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___nl",
    path: index1us1AaJFa5Meta?.path ?? "/nl/arrangementen",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___de",
    path: index1us1AaJFa5Meta?.path ?? "/de/arrangements",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___en",
    path: reviewsp0OUKSelxuMeta?.path ?? "/reviews",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___no",
    path: reviewsp0OUKSelxuMeta?.path ?? "/no/anmeldelser",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___nl",
    path: reviewsp0OUKSelxuMeta?.path ?? "/nl/beoordelingen",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___de",
    path: reviewsp0OUKSelxuMeta?.path ?? "/de/rezensionen",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: _91item_93bm9CCcgfkyMeta?.name ?? "rooms-item___en",
    path: _91item_93bm9CCcgfkyMeta?.path ?? "/rooms/:item()",
    meta: _91item_93bm9CCcgfkyMeta || {},
    alias: _91item_93bm9CCcgfkyMeta?.alias || [],
    redirect: _91item_93bm9CCcgfkyMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93bm9CCcgfkyMeta?.name ?? "rooms-item___no",
    path: _91item_93bm9CCcgfkyMeta?.path ?? "/no/rom/:item()",
    meta: _91item_93bm9CCcgfkyMeta || {},
    alias: _91item_93bm9CCcgfkyMeta?.alias || [],
    redirect: _91item_93bm9CCcgfkyMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93bm9CCcgfkyMeta?.name ?? "rooms-item___nl",
    path: _91item_93bm9CCcgfkyMeta?.path ?? "/nl/kamers/:item()",
    meta: _91item_93bm9CCcgfkyMeta || {},
    alias: _91item_93bm9CCcgfkyMeta?.alias || [],
    redirect: _91item_93bm9CCcgfkyMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93bm9CCcgfkyMeta?.name ?? "rooms-item___de",
    path: _91item_93bm9CCcgfkyMeta?.path ?? "/de/zimmer/:item()",
    meta: _91item_93bm9CCcgfkyMeta || {},
    alias: _91item_93bm9CCcgfkyMeta?.alias || [],
    redirect: _91item_93bm9CCcgfkyMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: indexshyPbhYt8IMeta?.name ?? "rooms___en",
    path: indexshyPbhYt8IMeta?.path ?? "/rooms",
    meta: indexshyPbhYt8IMeta || {},
    alias: indexshyPbhYt8IMeta?.alias || [],
    redirect: indexshyPbhYt8IMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: indexshyPbhYt8IMeta?.name ?? "rooms___no",
    path: indexshyPbhYt8IMeta?.path ?? "/no/rom",
    meta: indexshyPbhYt8IMeta || {},
    alias: indexshyPbhYt8IMeta?.alias || [],
    redirect: indexshyPbhYt8IMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: indexshyPbhYt8IMeta?.name ?? "rooms___nl",
    path: indexshyPbhYt8IMeta?.path ?? "/nl/kamers",
    meta: indexshyPbhYt8IMeta || {},
    alias: indexshyPbhYt8IMeta?.alias || [],
    redirect: indexshyPbhYt8IMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: indexshyPbhYt8IMeta?.name ?? "rooms___de",
    path: indexshyPbhYt8IMeta?.path ?? "/de/zimmer",
    meta: indexshyPbhYt8IMeta || {},
    alias: indexshyPbhYt8IMeta?.alias || [],
    redirect: indexshyPbhYt8IMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: shopbVVgDM89cGMeta?.name ?? "shop___en",
    path: shopbVVgDM89cGMeta?.path ?? "/shop",
    meta: shopbVVgDM89cGMeta || {},
    alias: shopbVVgDM89cGMeta?.alias || [],
    redirect: shopbVVgDM89cGMeta?.redirect,
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: shopbVVgDM89cGMeta?.name ?? "shop___no",
    path: shopbVVgDM89cGMeta?.path ?? "/no/shop",
    meta: shopbVVgDM89cGMeta || {},
    alias: shopbVVgDM89cGMeta?.alias || [],
    redirect: shopbVVgDM89cGMeta?.redirect,
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: shopbVVgDM89cGMeta?.name ?? "shop___nl",
    path: shopbVVgDM89cGMeta?.path ?? "/nl/shop",
    meta: shopbVVgDM89cGMeta || {},
    alias: shopbVVgDM89cGMeta?.alias || [],
    redirect: shopbVVgDM89cGMeta?.redirect,
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: shopbVVgDM89cGMeta?.name ?? "shop___de",
    path: shopbVVgDM89cGMeta?.path ?? "/de/shop",
    meta: shopbVVgDM89cGMeta || {},
    alias: shopbVVgDM89cGMeta?.alias || [],
    redirect: shopbVVgDM89cGMeta?.redirect,
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___en",
    path: sitemapmtRscZaA8vMeta?.path ?? "/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___no",
    path: sitemapmtRscZaA8vMeta?.path ?? "/no/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___nl",
    path: sitemapmtRscZaA8vMeta?.path ?? "/nl/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___de",
    path: sitemapmtRscZaA8vMeta?.path ?? "/de/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  }
]